import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../components/SEO"

const TechNoteEntries = graphql`
  query getTechNoteEntries {
    notes: allFile(
      filter: {
        sourceInstanceName: { eq: "techNotes" }
        extension: { eq: "mdx" }
        childMdx: { fields: { show: { eq: true } } }
      }
    ) {
      edges {
        node {
          relativeDirectory
          name
        }
      }
    }
  }
`

const TechNotes = () => {
  const { notes } = useStaticQuery(TechNoteEntries)

  let topics = new Set()

  notes.edges.forEach(({ node }, index) => {
    topics.add(node.relativeDirectory)
  })

  var content = {}
  topics.forEach(topic => {
    content[topic] = []
  })

  notes.edges.forEach(({ node }, index) => {
    content[node.relativeDirectory].push(node.name)
  })

  return (
    <Layout>
      <SEO title="Technical Notes" />
      <Wrapper>
        <div className="container">
          <h1>Technical Notes</h1>
          <p>
            What follows are notes from my code wandering in Python. These notes
            are written in Jupyter Notebooks and exported to MDX format, to be
            hosted on this blog.
          </p>
          <p>
            Treat these as recipes for specific solutions, or use them as a way
            to deep-dive into Python.
          </p>
          {Object.keys(content).map((topic, index) => {
            return (
              <div className="group" key={index}>
                <h3 className="topic">{topic.replace(/-/g, " ")}</h3>

                <div className="subtopics">
                  {content[topic].map((subtopic, subtopicIndex) => {
                    return (
                      <AniLink
                        fade
                        key={subtopicIndex}
                        className="subtopic"
                        to={`/tech/${topic}/${subtopic}`}
                      >
                        {subtopic.replace(/-/g, " ")}
                      </AniLink>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  .container {
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .group {
    display: flex;
    flex-direction: column;
    background-color: #f7f6f6;
    border-radius: 5px;
    margin-bottom: 1rem;

    h3 {
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: 500px) {
    .container {
      padding: 0 1em;
    }
  }

  .topic {
    font-weight: bold;
    font-family: var(--headerFont);
    letter-spacing: var(--mainSpacing);
    color: var(--primaryColor);
    width: 100%;
    padding: 0.5rem 0 0 0.5rem;
  }

  .subtopics {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .subtopic {
    margin: 0.5em 0.5em;
    padding: 0.5em 1em;
    background-color: var(--mainBlack);
    color: var(--mainWhite);
    border-radius: 5px;
    text-transform: capitalize;
  }
`

export default TechNotes
